var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        visible: _vm.dialogVisible,
        title: "自定义列表显示",
        width: "640px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticStyle: { width: "600px" } }, [
        _c("div", { staticClass: "table-head" }, [
          _c("table", [
            _c("colgroup", [
              _c("col", { staticStyle: { width: "80px" } }),
              _c("col", { staticStyle: { width: "80px" } }),
              _c("col", { staticStyle: { width: "200px" } }),
              _c("col", { staticStyle: { width: "100px" } }),
              _c("col"),
            ]),
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  [
                    _c("el-checkbox", {
                      on: { change: _vm.selectAll },
                      model: {
                        value: _vm.isSelectAll,
                        callback: function ($$v) {
                          _vm.isSelectAll = $$v
                        },
                        expression: "isSelectAll",
                      },
                    }),
                  ],
                  1
                ),
                _c("th", [_vm._v("序号")]),
                _c("th", [_vm._v("列表字段(勾选显示)")]),
                _c("th", [_vm._v("宽度")]),
                _c("th", [_vm._v("操作")]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "table-body" }, [
          _c("table", [
            _c("colgroup", [
              _c("col", { staticStyle: { width: "80px" } }),
              _c("col", { staticStyle: { width: "80px" } }),
              _c("col", { staticStyle: { width: "200px" } }),
              _c("col", { staticStyle: { width: "100px" } }),
              _c("col"),
            ]),
            _c(
              "tbody",
              { attrs: { id: "table-body" } },
              _vm._l(_vm.fieldList, function (item, $index) {
                return _c("tr", { key: item.filed, staticClass: "item" }, [
                  _c(
                    "td",
                    [
                      _c("el-checkbox", {
                        attrs: { disabled: item.isMondary },
                        on: { change: _vm.handleChange },
                        model: {
                          value: item.selected,
                          callback: function ($$v) {
                            _vm.$set(item, "selected", $$v)
                          },
                          expression: "item.selected",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("td", [_vm._v(_vm._s($index + 1))]),
                  _c("td", [_vm._v(_vm._s(item.label))]),
                  _c(
                    "td",
                    [
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        model: {
                          value: item.width,
                          callback: function ($$v) {
                            _vm.$set(item, "width", $$v)
                          },
                          expression: "item.width",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "opt" },
                    [
                      item.isFixed
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/order/lock.png"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.lock(item, $index, false)
                              },
                            },
                          })
                        : _c("svg-icon", {
                            attrs: { iconClass: "lock", className: "icon" },
                            on: {
                              iconClick: function ($event) {
                                return _vm.lock(item, $index, true)
                              },
                            },
                          }),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/order/up.png"),
                          title: "置顶",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.up(item, $index)
                          },
                        },
                      }),
                      _c("svg-icon", {
                        attrs: { iconClass: "move", className: "icon" },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "table-footer" }, [
          _c("div", { staticClass: "unCheck", on: { click: _vm.backSelect } }, [
            _vm._v("反选"),
          ]),
          _c("div", { staticClass: "check-num" }, [
            _vm._v(" 已勾选 "),
            _c("span", [_vm._v(_vm._s(_vm.checkLength))]),
            _vm._v(" 项 "),
          ]),
        ]),
      ]),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "cancel", on: { click: _vm.cancel } }, [
            _vm._v("取消"),
          ]),
          _c("div", { staticClass: "ok", on: { click: _vm.ok } }, [
            _vm._v("保存设置"),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }