<template>
  <el-dialog
    v-drag
    :visible.sync="dialogVisible"
    title="自定义列表显示"
    width="640px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div style="width: 600px">
      <div class="table-head">
        <table>
          <colgroup>
            <col style="width: 80px" />
            <col style="width: 80px" />
            <col style="width: 200px" />
            <col style="width: 100px" />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>
                <el-checkbox
                  v-model="isSelectAll"
                  @change="selectAll"
                ></el-checkbox>
              </th>
              <th>序号</th>
              <th>列表字段(勾选显示)</th>
              <th>宽度</th>
              <th>操作</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="table-body">
        <table>
          <colgroup>
            <col style="width: 80px" />
            <col style="width: 80px" />
            <col style="width: 200px" />
            <col style="width: 100px" />
            <col />
          </colgroup>
          <tbody id="table-body">
            <tr
              v-for="(item, $index) in fieldList"
              :key="item.filed"
              class="item"
            >
              <td>
                <el-checkbox
                  v-model="item.selected"
                  :disabled="item.isMondary"
                  @change="handleChange"
                ></el-checkbox>
              </td>
              <td>{{ $index + 1 }}</td>
              <td>{{ item.label }}</td>
              <td>
                <el-input
                  v-model="item.width"
                  style="width: 100px"
                ></el-input>
              </td>
              <td class="opt">
                <img
                  v-if="item.isFixed"
                  src="@/assets/images/order/lock.png"
                  @click="lock(item, $index, false)"
                />
                <svg-icon
                  v-else
                  iconClass="lock"
                  className="icon"
                  @iconClick="lock(item, $index, true)"
                />
                <img
                  src="@/assets/images/order/up.png"
                  title="置顶"
                  @click="up(item, $index)"
                />
                <svg-icon iconClass="move" className="icon" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer">
        <div class="unCheck" @click="backSelect">反选</div>
        <div class="check-num">
          已勾选 <span>{{ checkLength }}</span> 项
        </div>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel" @click="cancel">取消</div>
      <div class="ok" @click="ok">保存设置</div>
    </span>
  </el-dialog>
</template>

<script>
import Sortable from 'sortablejs'
import { getSystemTab, TableUserAdd } from '@/api/system'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    useFildList: {
      type: Array,
      default: function() {
        return []
      }
    },
    useLogId: {
      type: String,
      default: ''
    },
    tableCode: {
      type: String,
      default: 'order'
    },
    tabCode: {
      type: String,
      default: 'DCL-0'
    }
  },
  data() {
    return {
      fieldList: [],
      isSelectAll: false
    }
  },
  computed: {
    checkLength: function() {
      let num = 0
      this.fieldList.forEach((item) => {
        if (item.selected) {
          num += 1
        }
      })
      return num
    }
  },
  watch: {
    async dialogVisible(val) {
      if (val) {
      //  const systemConfigList = this.useFildList.length ? this.useFildList : await this.getSystemConfig()
      //   if (this.useFildList && this.useFildList.length > 0) {
      //     const arr = systemConfigList.map((item) => {
      //       const flag = this.useFildList.find((a) => a.filed === item.filed)
      //       if (flag) {
      //         return {
      //           ...flag,
      //           tabCode: this.tabCode
      //         }
      //       } else {
      //         return {
      //           ...item,
      //           tabCode: this.tabCode
      //         }
      //       }
      //     })
      //     this.fieldList = arr
      //   }
      //   this.$nextTick(() => {
      //     this.init()
      //   })
      if (this.useFildList && this.useFildList.length > 0) {
          const arr = this.fieldList.map((item) => {
            const flag = this.useFildList.find((a) => a.filed === item.filed)
            if (flag) {
              return {
                ...flag,
                tabCode: this.tabCode
              }
            } else {
              return {
                ...item,
                tabCode: this.tabCode
              }
            }
          })
          this.fieldList = arr
        }
        this.$nextTick(() => {
          this.init()
        })
      }
    }
  },
  created() {
   this.getSystemConfig()
  },
  methods: {
    // async getSystemConfig() {
    //   try {
    //     const res = await getSystemTab({
    //       tableCode: this.tableCode,
    //       tabCode: this.tabCode
    //     })
    //     if (res.success) {
    //        return res.data
    //     } else {
    //       return []
    //     }
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },
    async getSystemConfig() {
      try {
        const res = await getSystemTab({
          tableCode: this.tableCode
        })
        if (res.success) {
          this.fieldList = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    async ok() {
      const flag = this.checkList()
      if (!flag) {
        this.$message.error('未固定列不允许在固定列之前，请修改后再次保存')
        return
      }
      try {
        const res = await TableUserAdd({
          tableCode: this.tableCode,
          id: this.useLogId || undefined,
          value: JSON.stringify(this.fieldList),
          tabCode: this.tabCode
        })
        if (res.success) {
          this.$message.success('设置成功')
          this.$emit('ok')
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    init() {
      // const that = this
      const el = document.getElementById('table-body')
      // 常用
      new Sortable(el, {
        filter: '.ignore-elements',
        onEnd: (evt) => {
          // 获取排序之后的data数据
          if (
            this.fieldList[evt.oldIndex].isFixed &&
            !this.fieldList[evt.newIndex].isFixed
          ) {
            this.$message.warning('已固定列禁止拖拽至未固定列之后')
            this.renderList()
            return
          } else if (
            this.fieldList[evt.newIndex].isFixed &&
            !this.fieldList[evt.oldIndex].isFixed
          ) {
            this.$message.warning('未固定列禁止拖拽至未固定列之前')
            this.renderList()
            return
          } else {
            this.fieldList.splice(
              evt.newIndex,
              0,
              this.fieldList.splice(evt.oldIndex, 1)[0]
            )
            this.renderList()
          }
        }
      })
    },
    lock(item, index, type) {
      item.isFixed = type
      this.fieldList.splice(index, 1, item)
      this.renderList()
    },
    up(item, index) {
      this.fieldList.splice(index, 1)
      const unlockIndex = this.fieldList.findIndex(a => !a.isFixed)
      this.fieldList.splice(unlockIndex, 0, item)
      this.renderList()
    },
    checkList() {
      let flag = true
      this.fieldList.forEach((item, index) => {
        if (
          !item.isFixed &&
          index < this.fieldList.length - 1 &&
          this.fieldList[index + 1].isFixed
        ) {
          flag = false
        }
      })
      return flag
    },
    handleChange() {
      this.renderList()
    },
    selectAll() {
      this.fieldList.forEach((item) => {
        if (!item.isMondary) {
          item.selected = this.isSelectAll
        }
      })
      this.renderList()
    },
    backSelect() {
      this.fieldList.forEach((item) => {
        if (!item.isMondary) {
          item.selected = !item.selected
        }
      })
      this.renderList()
    },
    renderList() {
      const newArray = this.fieldList.slice(0)
      this.fieldList = []
      this.$nextTick(function() {
        this.fieldList = newArray
      })
    }
  }
}
</script>

<style lang="less" scoped>
.table-container {
  width: 100%;
  height: 400px;
}
table {
  border-collapse: collapse;
}
.table-head {
  height: 40px;
  background: #F7F7F7;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
  border: 1px solid #EEEEEE;
  line-height: 40px;
  text-align: center;
  th {
    height: 100%;
    border-right: 1px solid #EEEEEE;
    &:last-child {
      border-right: 0;
    }
  }
}
.table-body {
  height: 400px;
  overflow-y: auto;
  border: 1px solid #EEEEEE;
  border-top: 0;
  line-height: 40px;
  text-align: center;
  tr {
    width: 600px;
    height: 40px;
    border-bottom: 1px solid #EEEEEE;
    cursor: move;
  }
  td {
    height: 100%;
    border-right: 1px solid #EEEEEE;
    &:last-child {
      border-right: 0;
    }
  }
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .opt {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
  }
  .el-input {
    /deep/ input {
      border: 0;
    }
  }
}
.table-head table,
.table-body table {
  width: 100%;
}
.table-footer {
  width: calc(100% - 2px);
  height: 40px;
  display: flex;
  border: 1px solid #EEEEEE;
  border-radius: 0px 0px 6px 6px;
  border-top: 0;
  .unCheck {
    width: 80px;
    height: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #2395f3;
    line-height: 40px;
    text-align: center;
    border-right: 1px solid #EEEEEE;
    cursor: pointer;
  }
  .check-num {
    width: calc(100% - 81px);
    height: 100%;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 40px;
    text-align: center;
    span {
      color: #2395f3;
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #EEEEEE;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.icon {
  font-size: 20px;
  color: #2395f3;
  &:first-child {
    cursor: pointer;
  }
}
</style>
